export const workingHoursFormFields = {
  data() {
    return {
      formFields: [
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        },
        {
          activated: false,
          disabled: true,
          start_hours: '',
          start_minutes: '',
          end_hours: '',
          end_minutes: '',
          min_start_time: '',
          max_end_time: '',
          hours_list: []
        }
      ]
    }
  }
}
