<template>
  <main class="navbar-offset-tablet salon-flow">
    <div v-if="dataLoaded" class="px-1-5">
      <div class="row">
        <div class="col-md-12 px-0">
          <div v-for="(day, daykey) in workDays" :key="`day_${daykey}`" class="row" :class="{ 'mt-1-5': daykey !== 0 }">
            <div class="col-12 p-1-5 bg-white border rounded border-light">
              <div class="row">
                <div class="col-12 checkbox ml-1">
                  <label class="mb-0 d-inline-block cursor-pointer">
                    <input
                      v-model="formFieldsCalculated[daykey].activated"
                      type="checkbox"
                      :checked="formFieldsCalculated[daykey].activated"
                      :disabled="formFieldsCalculated[daykey].disabled"
                      class="activateWorkingHours"
                    />
                    <i class="input-helper"></i>
                    <div class="text-black h6 mb-0">{{ weekdayList[daykey] }}</div>
                  </label>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-12 col-md-6 mb-1-5 mb-md-0">
                  <div class="mb-1-5">
                    <span class="text-black">{{ $t('salon-employees.choose_working_hours') }}</span>
                  </div>

                  <div class="d-flex flex-wrap align-items-center">
                    <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-1-5">
                      {{ $t('salon-employees.form-label.from') }}
                    </p>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="formFieldsCalculated[daykey].start_hours"
                        v-validate="formFieldsCalculated[daykey].activated ? selectValidateRule : ''"
                        :disabled="!formFieldsCalculated[daykey].activated"
                        :name="`start_hours_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in formFieldsCalculated[daykey].startHoursList"
                          :key="`start_hours_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="formFieldsCalculated[daykey].start_minutes"
                        v-validate="formFieldsCalculated[daykey].activated ? selectValidateRule : ''"
                        :disabled="!formFieldsCalculated[daykey].activated"
                        :name="`start_minutes_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in formFieldsCalculated[daykey].startMinutetsList"
                          :key="`start_minutes_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <p class="text-small text-black mb-0 ml-1 ml-md-5 font-weight-medium text-nowrap mr-1-5">
                      {{ $t('salon-employees.form-label.to') }}
                    </p>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="formFieldsCalculated[daykey].end_hours"
                        v-validate="formFieldsCalculated[daykey].activated ? selectValidateRule : ''"
                        :disabled="!formFieldsCalculated[daykey].activated"
                        :name="`end_hours_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in formFieldsCalculated[daykey].endHoursList"
                          :key="`end_hours_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="formFieldsCalculated[daykey].end_minutes"
                        v-validate="formFieldsCalculated[daykey].activated ? selectValidateRule : ''"
                        :disabled="!formFieldsCalculated[daykey].activated"
                        :name="`end_minutes_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in formFieldsCalculated[daykey].endMinutetsList"
                          :key="`end_minutes_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>

                    <span
                      v-show="
                        errors.has(`end_minutes_${daykey}`) ||
                          errors.has(`end_hours_${daykey}`) ||
                          errors.has(`end_minutes_${daykey}`) ||
                          errors.has(`start_hours_${daykey}`) ||
                          errors.has(`start_minutes_${daykey}`)
                      "
                      class="error-message mt-1 col-12 pl-0"
                    >
                      {{ $t('salon-working-hours.errors.full-day-select') }}
                    </span>
                  </div>
                </div>

                <div class="col-12 col-md-auto">
                  <div class="d-flex justify-content-between align-items-center mb-1-5">
                    <span class="text-black">{{ $t('salon-employees.choose_lunch_time') }}</span>
                    <b-button
                      v-if="lunchformFieldsCalculated[daykey].start_hours"
                      variant="link"
                      class="p-0"
                      @click.prevent="resetLunchtimeByDayKey(daykey)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'sync-alt']"
                        class="ml-0-5 text-gray"
                        style="font-size:14px;"
                      ></font-awesome-icon>
                    </b-button>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <p class="text-small text-black mb-0 poppins-font font-weight-medium text-nowrap mr-1-5">
                      {{ $t('salon-employees.form-label.from') }}
                    </p>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="lunchformFieldsCalculated[daykey].start_hours"
                        v-validate="
                          formFieldsCalculated[daykey].activated && lunchformFieldsCalculated[daykey].start_hours
                            ? selectValidateRule
                            : ''
                        "
                        :disabled="
                          !formFieldsCalculated[daykey].activated || lunchformFieldsCalculated[daykey].isDisabled
                        "
                        :name="`start_hours_lunch_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in lunchformFieldsCalculated[daykey].startHoursList"
                          :key="`start_hours_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="lunchformFieldsCalculated[daykey].start_minutes"
                        v-validate="
                          formFieldsCalculated[daykey].activated && lunchformFieldsCalculated[daykey].start_hours
                            ? selectValidateRule
                            : ''
                        "
                        :disabled="
                          !formFieldsCalculated[daykey].activated || lunchformFieldsCalculated[daykey].isDisabled
                        "
                        :name="`start_minutes_lunch_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in lunchformFieldsCalculated[daykey].startMinutetsList"
                          :key="`start_minutes_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <p class="text-small text-black mb-0 ml-1 ml-md-5 font-weight-medium text-nowrap mr-1-5">
                      {{ $t('salon-employees.form-label.to') }}
                    </p>
                    <div class="input-group mr-0-5 position-relative small-input">
                      <select
                        v-model="lunchformFieldsCalculated[daykey].end_hours"
                        v-validate="
                          formFieldsCalculated[daykey].activated && lunchformFieldsCalculated[daykey].start_hours
                            ? selectValidateRule
                            : ''
                        "
                        :disabled="
                          !formFieldsCalculated[daykey].activated || lunchformFieldsCalculated[daykey].isDisabled
                        "
                        :name="`end_hours_lunch_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in lunchformFieldsCalculated[daykey].endHoursList"
                          :key="`end_hours_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                    <div class="input-group position-relative small-input">
                      <select
                        v-model="lunchformFieldsCalculated[daykey].end_minutes"
                        v-validate="
                          formFieldsCalculated[daykey].activated && lunchformFieldsCalculated[daykey].start_hours
                            ? selectValidateRule
                            : ''
                        "
                        :disabled="
                          !formFieldsCalculated[daykey].activated || lunchformFieldsCalculated[daykey].isDisabled
                        "
                        :name="`end_minutes_lunch_${daykey}`"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                      >
                        <option
                          v-for="(option, key) in lunchformFieldsCalculated[daykey].endMinutetsList"
                          :key="`end_minutes_${key}`"
                          :value="option"
                          >{{ option }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <span
                    v-show="
                      errors.has(`end_hours_lunch_${daykey}`) ||
                        errors.has(`end_minutes_lunch_${daykey}`) ||
                        errors.has(`start_hours_lunch_${daykey}`) ||
                        errors.has(`start_minutes_lunch_${daykey}`)
                    "
                    class="error-message mt-1 col-12 pl-0"
                  >
                    {{ $t('salon-working-hours.errors.full-day-select') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { workingHoursFormFields } from './mixins/workingHoursFormFields'
import { lunchHoursFormFields } from './mixins/lunchHoursFormFields'

import TimeConstants from '@/_constants/time.js'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SalonWorkingHours',
  mixins: [workingHoursFormFields, lunchHoursFormFields],
  props: {
    staffData: {
      type: Array,
      default: () => []
    },
    updateMode: Boolean
  },
  data() {
    return {
      dataLoaded: false,
      submitProcess: false,
      hoursList: TimeConstants.HOURS_LIST,
      minutesList: TimeConstants.MINUTES_LIST,
      selectValidateRule: 'required'
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      workDays: 'workDays/getWorkDays',
      siteLang: 'user/getSiteLang'
    }),
    weekdayList() {
      moment.updateLocale(this.siteLang, {
        week: {
          dow: 1
        }
      })

      return moment.localeData(this.siteLang).weekdays(true)
    },
    formFieldsCalculated() {
      return this.formFields.map(field => {
        // Calculate start minutes list
        field.startHoursList = field.hours_list

        let minMinuteStart = this.calcMinutes(field.min_start_time)
        let maxMinuteEnd = this.calcMinutes(field.max_end_time)
        let lastHourStart = field.startHoursList[field.startHoursList.length - 1]

        // Calculate end minutes list
        field.endHoursList = field.hours_list.filter(hour => hour >= field.start_hours)

        let lastHourEnd = field.hours_list[field.hours_list.length - 1]
        let firstHourEnd = field.hours_list[0]

        // Calculate start minutes list
        field.startMinutetsList = this.calculateStartMinutes(
          field.start_hours,
          lastHourStart,
          lastHourEnd,
          firstHourEnd,
          minMinuteStart,
          maxMinuteEnd
        )

        // Calculate end minutes list
        field.endMinutetsList = this.calculateEndMinutes(
          field.end_hours,
          field.start_hours,
          lastHourEnd,
          field.start_minutes,
          maxMinuteEnd
        )

        // if (!field.startMinutetsList.includes(field.start_minutes)) field.start_minutes = ''
        if (!field.endMinutetsList.includes(field.end_minutes)) field.end_minutes = ''
        if (!field.startHoursList.includes(field.start_hours)) field.start_hours = ''
        if (!field.endHoursList.includes(field.end_hours)) field.end_hours = ''

        if (field.end_hours === '') {
          field.end_minutes = ''
          field.endMinutetsList = []
        }
        if (
          typeof field.start_hours === 'string' &&
          typeof field.end_hours === 'string' &&
          typeof field.start_minutes === 'string' &&
          typeof field.end_minutes === 'string' &&
          this.dataLoaded &&
          this.updateMode
        ) {
          field.activated = false
        }
        return field
      })
    },
    lunchformFieldsCalculated() {
      return this.lunchHoursformFields.map((field, fieldIndex) => {
        let workingHoursField = this.formFieldsCalculated[fieldIndex]
        let startEqualStartWokingHours = field.start_hours === workingHoursField.start_hours
        let startEqualEndWokingHours = field.start_hours === workingHoursField.end_hours
        let endEqualEndWokingHours = field.end_hours === workingHoursField.end_hours
        let startEqualEnd = field.start_hours === field.end_hours

        field.isDisabled = Object.values(workingHoursField).some(val => val === '')

        // Calculate start hours list
        field.startHoursList = workingHoursField.startHoursList.filter((hour, index) => {
          return hour >= workingHoursField.start_hours && hour <= workingHoursField.end_hours
        })

        // Calculate end hours list
        field.endHoursList = workingHoursField.endHoursList.filter((hour, index) => {
          return hour >= field.start_hours && hour <= workingHoursField.end_hours
        })

        // Calculate start minutes hours list
        field.startMinutetsList = this.calculateLunchStartMinutes(
          startEqualStartWokingHours,
          startEqualEndWokingHours,
          workingHoursField.start_minutes,
          workingHoursField.end_minutes
        )

        // Calculate end minutes hours list
        field.endMinutetsList = this.calculateLunchEndMinutes(
          startEqualEnd,
          endEqualEndWokingHours,
          field.start_minutes,
          workingHoursField.end_minutes
        )

        if (!field.startHoursList.includes(field.start_hours)) field.start_hours = ''
        if (!field.endHoursList.includes(field.end_hours)) field.end_hours = ''
        if (!field.startMinutetsList.includes(field.start_minutes)) field.start_minutes = ''
        if (!field.endMinutetsList.includes(field.end_minutes)) field.end_minutes = ''
        if (field.start_hours === '') field.end_hours = ''

        if (field.start_hours === '') {
          field.start_minutes = ''
          field.startMinutetsList = []
        }
        if (field.end_hours === '') {
          field.end_minutes = ''
          field.endMinutetsList = []
        }

        return field
      })
    }
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.dataLoaded = false
      this.fetchWorkDays(this.accountInfo.salon_id).then(response => {
        this.setConditionWorkingHoursFields()

        this.dataLoaded = true
      })
    }
  },
  methods: {
    ...mapActions({
      fetchWorkDays: 'workDays/getWorkDays',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    resetLunchtimeByDayKey(key) {
      this.lunchformFieldsCalculated[key].start_hours = ''
      this.lunchformFieldsCalculated[key].start_minutes = ''
      this.lunchformFieldsCalculated[key].end_hours = ''
      this.lunchformFieldsCalculated[key].end_minutes = ''
    },
    collectPayloadData() {
      const daysPlayloadData = []

      this.formFields.forEach((fields, key) => {
        let working_hours = {
          id: null,
          start_time: null,
          end_time: null,
          start_lunch_time: null,
          end_lunch_time: null
        }

        working_hours.id = fields.id

        if (!fields.activated || Object.values(fields).some(val => val === '')) {
          daysPlayloadData.push(working_hours)
        } else {
          working_hours.start_time = this.calcFullTime(fields.start_hours, fields.start_minutes, fields)
          working_hours.end_time = this.calcFullTime(fields.end_hours, fields.end_minutes)

          working_hours.start_lunch_time = this.calcFullTime(
            this.lunchformFieldsCalculated[key].start_hours,
            this.lunchformFieldsCalculated[key].start_minutes
          )
          working_hours.end_lunch_time = this.calcFullTime(
            this.lunchformFieldsCalculated[key].end_hours,
            this.lunchformFieldsCalculated[key].end_minutes
          )

          daysPlayloadData.push(working_hours)
        }
      })

      const payload = {
        ...{ salon_id: this.accountInfo.salon_id },
        ...{ working_hours: daysPlayloadData }
      }

      return payload
    },
    setConditionWorkingHoursFields() {
      this.workDays.forEach((day, key) => {
        if (this.updateMode && this.formFields[key].id !== undefined) {
          this.formFields[key].id = this.staffData[key].id
        } else {
          this.formFields[key].id = day.work_day.id
        }

        if (day.start_time && day.end_time) {
          this.formFields[key].activated = true
          this.formFields[key].disabled = false

          let startHours = this.calcHours(day.start_time)
          let endHours = this.calcHours(day.end_time)
          let timeRange = this.timeRange(startHours, endHours)
          let startMinutes = this.calcMinutes(day.start_time)
          let endMinutes = this.calcMinutes(day.end_time)

          let startTime = startHours === 1 ? `0${startHours}:${startMinutes}` : day.start_time
          let endTime = endHours === 1 ? `0${endHours}:${endMinutes}` : day.end_time

          this.formFields[key].min_start_time = startTime
          this.formFields[key].max_end_time = endTime

          for (let i in timeRange) {
            let formatedHour = this.calcHours(timeRange[i])

            this.formFields[key].hours_list.push(formatedHour)
          }

          if (this.staffData[key]) {
            this.formFields[key].id = this.staffData[key].id
            if (!Object.values(this.staffData[key]).some(val => val === '' || val === 'null')) {
              this.formFields[key].start_hours = this.calcHours(this.staffData[key].start_time)
              this.formFields[key].start_minutes = this.calcMinutes(this.staffData[key].start_time)

              this.formFields[key].end_hours = this.calcHours(this.staffData[key].end_time)
              this.formFields[key].end_minutes = this.calcMinutes(this.staffData[key].end_time)

              this.lunchHoursformFields[key].start_hours = this.calcHours(this.staffData[key].start_lunch_time)
              this.lunchHoursformFields[key].start_minutes = this.calcMinutes(this.staffData[key].start_lunch_time)

              this.lunchHoursformFields[key].end_hours = this.calcHours(this.staffData[key].end_lunch_time)
              this.lunchHoursformFields[key].end_minutes = this.calcMinutes(this.staffData[key].end_lunch_time)
            }
          }
        }
      })
    },
    timeRange(startHours, endHours) {
      let formatedStartHours = moment(`2020-01-01 ${startHours}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
      let formatedEndHours = moment(`2020-01-01 ${endHours}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')

      const range = moment.range(formatedStartHours, formatedEndHours)
      const hours = Array.from(range.by('hour', { excludeStart: false, excludeEnd: false }))

      return hours.map(m => m.format('HH:mm'))
    },
    calcFullTime(hours, minutes) {
      return `${hours}:${minutes}`
    },
    calcHours(time) {
      return Number(time.split(':')[0])
    },
    calcMinutes(time) {
      let minutes = Number(time.split(':')[1])

      if (minutes === 0) {
        minutes = '00'
      }

      return minutes
    },
    calculateStartMinutes(startHour, lastHourStart, lastHourEnd, firstHourEnd, minMinuteStart, maxMinuteEnd) {
      let minutes = []

      if (startHour === lastHourStart && startHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => Number(minute) <= Number(maxMinuteEnd))
      } else if (startHour === firstHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= Number(minMinuteStart))
      } else if (startHour === lastHourEnd && startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) <= Number(maxMinuteEnd))
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateEndMinutes(endHour, startHour, lastHourEnd, startMinute, maxMinuteEnd) {
      let minutes = []

      if (endHour === lastHourEnd) {
        minutes = this.minutesList.filter(minute => {
          if (startHour === lastHourEnd) {
            return Number(minute) >= Number(startMinute) && Number(minute) <= Number(maxMinuteEnd)
          } else {
            return minute <= maxMinuteEnd
          }
        })
      } else if (endHour === startHour) {
        minutes = this.minutesList.filter(minute => Number(minute) >= Number(startMinute))
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateLunchStartMinutes(startEqualStartWokingHours, startEqualEndWokingHours, startMinutes, endMinutes) {
      let minutes = []

      if (startEqualStartWokingHours && startEqualEndWokingHours) {
        minutes = this.minutesList.filter(
          minute => Number(minute) >= Number(startMinutes) && Number(minute) <= Number(endMinutes)
        )
      } else if (startEqualStartWokingHours && !startEqualEndWokingHours) {
        minutes = this.minutesList.filter(minute => Number(minute) >= Number(startMinutes))
      } else if (!startEqualStartWokingHours && startEqualEndWokingHours) {
        minutes = this.minutesList.filter(minute => Number(minute) <= Number(endMinutes))
      } else {
        minutes = this.minutesList
      }

      return minutes
    },
    calculateLunchEndMinutes(startEqualEnd, endEqualEndWokingHours, startMinutes, endMinutes) {
      let minutes = []

      if (startEqualEnd && endEqualEndWokingHours) {
        minutes = this.minutesList.filter(
          minute => Number(minute) >= Number(startMinutes) && Number(minute) <= Number(endMinutes)
        )
      } else if (startEqualEnd && !endEqualEndWokingHours) {
        minutes = this.minutesList.filter(minute => Number(minute) >= Number(startMinutes))
      } else if (!startEqualEnd && endEqualEndWokingHours) {
        minutes = this.minutesList.filter(minute => Number(minute) <= Number(endMinutes))
      } else {
        minutes = this.minutesList
      }

      return minutes
    }
  }
}
</script>

<style lang="scss" scoped></style>
