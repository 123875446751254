<template>
  <div class="col-12 mb-1-5 border p-1 rounded">
    <div class="row align-items-center">
      <div class="col-6">
        <h6 class="mb-0">{{ service.title }}:</h6>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <a class="btn btn-link text-secondary" @click="removeSelectedServicesEmit(service.id)">
          <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14" style="font-size: 18px;"></font-awesome-icon>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr class="mt-0-5" />
        <p class="mb-1-5 text-black">{{ $t('salon-employees.for_whom_the_service') }}</p>
      </div>
      <div class="col-12">
        <div class="row text-center for-whom">
          <div v-for="parameter in parameters" :key="`parameter-${parameter.id}`" class="col">
            <label class="w-100 position-relative mb-0">
              <input
                v-model="selectedParameters"
                v-validate="'required'"
                type="checkbox"
                name="combination"
                class="btn-checkbox"
                autocomplete="new-password"
                :value="parameter.id"
              />
              <span class="btn btn-checkbox btn-block pl-md-3 pl-lg-1">
                {{ $t(`common.whom_key.${parameter.value}`) }}
              </span>
            </label>
          </div>
        </div>
        <div class="row">
          <span v-show="errors.has('combination')" class="error-message mt-1 col-12">{{
            errors.first('combination')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { uniqBy, uniq } from 'lodash'

export default {
  name: 'StaffServiceCard',
  props: {
    service: {
      type: Object,
      default: () => ({})
    },
    loadedServices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // FOR DETECT GENDER IN COMBINATIONS
      genderIdsList: [1, 2, 3, 4],
      selectedParameters: []
    }
  },
  computed: {
    parameters() {
      let parameters = []

      this.service.combinations.forEach(combination => {
        combination.parameter_values.forEach(parameter => {
          if (this.genderIdsList.includes(parameter.id)) {
            parameters.push(parameter)
          }
        })
      })

      return uniqBy(parameters, 'id')
    },
    formatedData() {
      let data = {
        id: this.service.id,
        parameters: this.selectedParameters
      }

      return data
    }
  },
  mounted() {
    let service = this.loadedServices.find(service => service.id === this.service.id)

    if (service !== undefined && service.parameters.length > 0) {
      this.selectedParameters = uniq(service.parameters)
    } else {
      this.selectedParameters = this.parameters.map(parameter => parameter.id)
    }
  },
  methods: {
    removeSelectedServicesEmit(id) {
      this.$emit('remove-selected-services', id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
