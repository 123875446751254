<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="
        isUpdateProfilePage
          ? $tc('salon-employees.breadcrumbs-update-label', 1)
          : $tc('salon-employees.breadcrumbs-create-label', 1)
      "
      :has-back="true"
      :button-back-link="'/salon/staff'"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <button
            v-if="staffId"
            class="btn btn-checkbox px-1-5 py-0-8 mr-1-5"
            @click="deleteStaff({ id: staffId, role: 'Staff' })"
          >
            <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

            <span class="font-weight-regular roboto-font text-black">{{ $t('common.delete-btn') }}</span>
          </button>

          <button class="btn btn-primary px-4 py-1" @click="submitForm">
            <span class="font-weight-medium poppins-font">{{ $t('common.submit-btn') }}</span>
          </button>
        </div>
      </template>
    </PageNavigator>
    <div class="px-1-5 pt-7-5 full-page-height">
      <div v-if="!isWorkerProfileLoading" class="h-100">
        <form>
          <div class="row">
            <div class="col-12">
              <p class="font-weight-medium poppins-font mb-1-5 text-black">
                {{ $t('salon-employees.form-label.section_label_personal') }}
              </p>
            </div>
            <div class="col-auto pr-2-5">
              <div class="d-flex">
                <div class="position-relative edit-profile-img px-0 mb-3-5">
                  <img :src="profilePhotoToDisplay" alt class="w-100 h-100 image--cover rounded" />
                  <div class="position-absolute">
                    <input
                      id="customFile"
                      ref="profileImage"
                      v-validate="'ext:jpeg,jpg,png|size:5120'"
                      type="file"
                      accept="image/x-png,image/jpg,image/jpeg"
                      name="photo"
                      :data-vv-as="$t('salon-gallery.photo')"
                      class="custom-file-upload"
                      @change="onImageUpload"
                    />
                    <label class="custom-file-label" for="customFile">
                      <span class="photocamera-icon"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div :class="roleField.class">
                  <div class="d-flex">
                    <label
                      :for="roleField.name"
                      class="mb-0 poppins-font text-black font-weight-medium text-small pt-0-3"
                      >{{ $t(roleField.i18KeyText) }}</label
                    >
                    <div>
                      <p id="service-info-1" class="ml-0-3 pr-1 mb-0 pb-0-2">
                        <font-awesome-icon
                          v-b-tooltip
                          class="fa-w-16 ml-0-5 tooltipInfoIcon"
                          style="font-size:16px;"
                          data-toggle="tooltip"
                          :icon="['fas', 'info-circle']"
                        ></font-awesome-icon>
                      </p>
                      <b-tooltip
                        target="service-info-1"
                        :popover-style="{ color: '#ffffff !important', background: '#fff !important' }"
                        :custom-class="'show'"
                        class="mr-5 show"
                        triggers="focus hover"
                        placement="right"
                      >
                        <div class="text-left">
                          <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_1')"></span>
                          <br />
                          <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_2')"></span>
                          <br />

                          <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_3')"></span>
                          <br />
                        </div>
                      </b-tooltip>
                    </div>
                  </div>
                  <div class="mb-1-5">
                    <select
                      v-model="roleField.value"
                      name="staff"
                      class="form-control w-100 px-0-5 text-center default-appearance"
                      style="font-size:14px;"
                      :disabled="!['owner', 'admin'].includes(this.userRole)"
                    >
                      <option v-for="(role, key) in roleField.data" :key="`staff_${key}`" :value="role.value">{{
                        $t(role.i18KeyTitle)
                      }}</option>
                    </select>
                  </div>
                </div>
                <div v-for="field in formFields" :key="field.name" :class="field.class">
                  <label :for="field.name" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                    {{ $t(field.i18KeyText) }}
                  </label>
                  <div :class="{ 'mb-1-5': !errors.has(field.name), 'input-group': field.name === 'password' }">
                    <input
                      v-if="field.type !== 'textarea'"
                      :id="field.name"
                      v-model="field.value"
                      v-validate="
                        isUpdateProfilePage && field.name === 'password'
                          ? field.value > 0
                            ? 'min:8|max:16'
                            : 'min:0|max:16'
                          : field.validationRules
                      "
                      :type="field.type"
                      :name="field.name"
                      :data-vv-as="$t(field.i18KeyText)"
                      autocomplete="new-password"
                      class="form-control w-100 px-1-5"
                    />
                    <div v-if="field.name === 'password'" class="input-group-append cursor-pointer" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-0.5 border-left-0">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                  </div>
                  <span v-show="errors.has(field.name)" class="error-message my-1 col-12 pl-0">{{
                    errors.first(field.name)
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-1-5">
            <div class="col-12 col-md-6 d-flex align-items-center border-top border-bottom border-right py-1-5">
              <div class="checkbox pl-3">
                <label
                  class="d-inline-block mb-0 font-weight-medium poppins-font text-small cursor-pointer pl-0 w-auto"
                >
                  <input v-model="useDynamicPricing" :true-value="1" :false-value="false" type="checkbox" />
                  <i class="input-helper"></i>

                  {{ $t('salon-employees.apply_dynamic_price') }}
                </label>

                <span class="font-weight-medium poppins-font text-small">
                  <span id="dinamic-prcing-1" class="ml-1 border-0 bg-transparent align-middle">
                    <font-awesome-icon
                      class="fa-w-16"
                      style="font-size:16px;"
                      data-toggle="tooltip"
                      :icon="['fas', 'info-circle']"
                    ></font-awesome-icon>
                  </span>

                  <b-tooltip
                    target="dinamic-prcing-1"
                    class="mr-1 border-0 bg-transparent"
                    triggers="hover click"
                    placement="bottom"
                    >{{ $t('tooltip_info.staff_first') }}</b-tooltip
                  >
                </span>
              </div>
            </div>

            <div class="col-12 col-md-6 pl-2-5 border-top border-bottom py-1-5">
              <div class="row">
                <div class="col-auto d-flex align-items-center checkbox">
                  <label class="d-inline-block mb-0 font-weight-medium poppins-font text-small cursor-pointer pl-2">
                    <input v-model="useDynamicPricing" :true-value="0" :false-value="false" type="checkbox" />
                    <i class="input-helper"></i>
                    {{ $t('salon-employees.percentage_dynamic_price_label') }}
                  </label>
                  <span id="dinamic-prcing-2" class="ml-1 border-0 bg-transparent align-middle">
                    <font-awesome-icon
                      class="fa-w-16"
                      style="font-size:16px;"
                      data-toggle="tooltip"
                      :icon="['fas', 'info-circle']"
                    ></font-awesome-icon>
                  </span>

                  <b-tooltip
                    target="dinamic-prcing-2"
                    class="mr-1 border-0 bg-transparent"
                    triggers="hover click"
                    placement="bottom"
                    >{{ $t('salon-employees.apprentice_info') }}</b-tooltip
                  >
                </div>

                <div
                  v-if="useDynamicPricing === 0"
                  class="col-12 col-sm d-flex align-items-center text-nowrap font-weight-medium poppins-font text-small mt-1-5 mt-sm-0"
                >
                  {{ $t('salon-employees.percentage_dynamic_price_discount') }}
                  <VueSlider v-model.number="discount" v-bind="discountOptions" class="col px-1-5" />
                  {{ discount }}%
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="font-weight-medium poppins-font mb-1-5 text-black">
                {{ $t('salon-employees.form-label.section_label_personal') }}
              </p>
            </div>

            <div class="col-12">
              <b-tabs content-class="mt-1-5" fill class="custom-tabs">
                <b-tab title-link-class="justify-content-center" title-item-class="w-50" active>
                  <template v-slot:title>
                    <div class="d-sm-flex justify-content-between align-items-center w-100 px-1-5">
                      <span>
                        <font-awesome-icon :icon="['far', 'cog']" class="mr-0-5"></font-awesome-icon>
                        <span class="text-black">Services</span>
                      </span>
                      <button class="btn btn-primary px-4 py-1" @click="showServiceModal()">
                        <span class="font-weight-medium poppins-font">{{ $t('common.add_service') }}</span>
                      </button>
                    </div>
                  </template>

                  <div v-if="selectedServices.length > 0" class="row">
                    <div
                      v-for="service in selectedServices"
                      :key="`serivce-card-${service.id}`"
                      class="col-12 col-sm-6"
                    >
                      <StaffServiceCard
                        ref="StaffServiceCard"
                        :service="service"
                        :loaded-services="storeServices"
                        @remove-selected-services="removeSelectedServices"
                      />
                    </div>
                  </div>
                  <div v-else class="row pb-3">
                    <div class="col-12">Services not selected</div>
                  </div>
                </b-tab>
                <b-tab title-link-class="justify-content-center" title-item-class="w-50">
                  <template v-slot:title>
                    <span>
                      <font-awesome-icon :icon="['far', 'clock']" class="mr-0-5"></font-awesome-icon>
                      <span class="text-black">{{ $t('salon-employees.working_hours') }}</span>
                    </span>
                  </template>
                  <WorkingHours
                    ref="WorkingHours"
                    :staff-data="storeWorkDayData"
                    :update-mode="staffId !== undefined"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </form>

        <b-modal id="serviceModal" centered title="Select Service" title-class="text-center w-100" :hide-footer="true">
          <StaffServiceForm
            ref="staffServiceForm"
            :services="salonServices"
            :selected-services="serviceList"
            @set-selected-services="setSelectedServices"
          />
        </b-modal>
      </div>
    </div>
  </main>
</template>

<script>
import { compressedFile } from '@/_mixins/compressedFile.js'

import PageNavigator from '@/components/PageNavigator'
import StaffServiceCard from '@/components/salon/staff/StaffServiceCard'
import StaffServiceForm from '@/components/salon/staff/StaffServiceForm'
import WorkingHours from '@/components/salon/staff/WorkingHours'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import { mapGetters, mapActions } from 'vuex'
import { mapValues, keyBy } from 'lodash'

export default {
  name: 'SalonStaffsForm',
  components: {
    PageNavigator,
    StaffServiceCard,
    StaffServiceForm,
    WorkingHours,
    VueSlider
  },
  mixins: [compressedFile],
  data() {
    return {
      profilePhotoToDisplay: '',
      isWorkerProfileLoading: false,
      avatar: null,
      storeServices: [],
      storeWorkDayData: [],
      cachedEmail: '',
      useDynamicPricing: false,
      discount: 0,
      serviceCardIsValidStatuses: [],
      roleField: {
        name: 'role',
        value: 0,
        i18KeyText: 'salon-employees.table-label.role',
        type: 'text',
        class: 'col-12 col-md-4',
        data: [
          {
            visible: true,
            value: 0,
            i18KeyTitle: 'role.Staff'
          },
          {
            visible: true,
            value: 1,
            i18KeyTitle: 'role.Salon Manager'
          }
        ]
      },
      formFields: [
        {
          name: 'first_name',
          value: '',
          validationRules: 'required|max:50',
          i18KeyText: 'salon-employees.form-label.first_name',
          type: 'text',
          class: 'col-12 col-md-4'
        },
        {
          name: 'last_name',
          value: '',
          validationRules: 'required|max:50',
          i18KeyText: 'salon-employees.form-label.last_name',
          type: 'text',
          class: 'col-12 col-md-4'
        },

        {
          name: 'email',
          value: '',
          validationRules: 'required|email|max:50',
          i18KeyText: 'salon-employees.form-label.email',
          type: 'email',
          class: 'col-12 col-md-3'
        },
        {
          name: 'password',
          value: '',
          validationRules: 'required|min:8|max:16',
          i18KeyText: 'salon-employees.form-label.password',
          type: 'password',
          class: 'col-12 col-md-3'
        },
        {
          name: 'specialization',
          value: '',
          validationRules: 'required|max:50',
          i18KeyText: 'salon-employees.form-label.specialization',
          i18KeyPlaceholder: 'salon-employees.form-label.specialization_placheholder',
          type: 'text',
          class: 'col-12 col-md-3'
        },
        {
          name: 'experience',
          value: '',
          validationRules: 'required|numeric|max:9',
          i18KeyText: 'salon-employees.form-label.experience',
          i18KeyPlaceholder: 'salon-employees.form-label.experience_placeholder',
          type: 'text',
          class: 'col-12 col-md-3'
        },
        {
          name: 'description',
          value: '',
          validationRules: 'max:255',
          i18KeyText: 'salon-employees.form-label.description',
          type: 'text',
          class: 'col-12'
        }
      ],
      serviceList: [],
      serviceListSaved: [],
      serviceListForDelete: [],
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      },
      deleteErrorMessage: {
        type: 'error',
        messageI18Key: 'common.alerts.actions.global_error'
      },
      discountOptions: {
        min: 0,
        max: 50,
        interval: 1,
        height: 6,
        tooltip: 'none'
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues',
      getServicesListByTypes: 'services/getServicesListByTypes',
      accountInfo: 'user/getAccountInfo',
      workerProfileInfo: 'salonsStaff/getProfileInfo',
      salonServices: 'services/getSalonServicesSelect',
      userRole: 'user/getRole'
    }),
    passwordIcon() {
      let passwordField = this.formFields.find(field => field.name === 'password')

      return passwordField.type === 'password' ? 'eye' : 'eye-slash'
    },
    staffId() {
      return this.$route.params.id
    },
    isUpdateProfilePage() {
      return this.$router.currentRoute.name === 'staff-update'
    },
    fieldsToChange() {
      let fieldsToChange = {}
      let workerAvatar = ''

      if (this.workerProfileInfo) {
        const workerProfileInfo = this.workerProfileInfo

        workerAvatar = workerProfileInfo.avatar ? workerProfileInfo.avatar.path : ''
      }

      if (this.profilePhotoToDisplay !== workerAvatar) fieldsToChange.avatar = this.avatar

      return fieldsToChange
    },
    payloadData() {
      let personalInfoData = mapValues(keyBy(this.formFields, 'name'), 'value')

      return personalInfoData
    },
    selectedServices() {
      if (this.salonServices) {
        return this.salonServices.filter(service => this.serviceList.includes(service.id))
      }

      return []
    }
  },
  watch: {
    useDynamicPricing(newVal) {
      if (newVal !== 0) {
        this.discount = 0
      }
    }
  },
  mounted() {
    this.fetchParamsValues()

    if (this.accountInfo.salon_id) {
      this.getSalonServicesSelect(this.accountInfo.salon_id).then(response => {
        this.dataLoaded = true
      })
    }
    if (this.isUpdateProfilePage) {
      this.isWorkerProfileLoading = true
      this.getStaffProfileInfo(this.$router.currentRoute.params.id).then(() => {
        const storedProfileInfo = { ...this.workerProfileInfo }
        this.setFormFields(storedProfileInfo)
        this.isWorkerProfileLoading = false
      })
    }
  },
  methods: {
    ...mapActions({
      addStaff: 'salonsStaff/createStaff',
      updateStaffProfileInfo: 'salonsStaff/updateStaffProfileInfo',
      getStaffProfileInfo: 'salonsStaff/getStaffProfileInfo',
      fetchParamsValues: 'haircutParams/getParamsValues',
      deleteStaffAction: 'salonsStaff/deleteStaff',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess',
      getSalonServicesSelect: 'services/getSalonServicesSelect'
    }),
    changeType() {
      let passwordField = this.formFields.find(field => field.name === 'password')

      passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
    },
    async submitForm() {
      const childIsValidate = !this.serviceCardIsValidStatuses.includes(false) && (await this.validateWorkingHours())

      if (!childIsValidate) return false

      let payload = this.collectMainPayload()

      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.isUpdateProfilePage) {
            // TODO: add error message if email already taken
            this.updateStaffProfileInfo(payload)
              .then(() => {
                this.isWorkerProfileLoading = false
                this.setSuccess({
                  messageI18Key: 'common.alerts.actions.updated',
                  type: 'successful_update'
                })
                this.$router.push('/salon/staff')
              })
              .catch(errors => {
                this.isWorkerProfileLoading = false

                for (let property in errors.data.messages) {
                  if (property === 'email') {
                    this.$validator.errors.add({
                      field: 'email',
                      msg: this.$t('common.error.wrong_email')
                    })
                  }
                }

                for (let property in errors.data.messages) {
                  if (property === 'services') {
                    this.setError({
                      messageI18Key: 'common.alerts.staff.service_not_found',
                      type: 'failed_creation'
                    })
                    return
                  }
                }

                this.setError({
                  messageI18Key: 'common.alerts.actions.global_error',
                  type: 'failed_update'
                })
              })
          } else {
            this.addStaff(payload)
              .then(() => {
                this.isWorkerProfileLoading = false
                this.setSuccess({
                  messageI18Key: 'common.alerts.actions.created',
                  type: 'successful_creation'
                })
                this.$router.push('/salon/staff')
              })
              .catch(errors => {
                this.isWorkerProfileLoading = false

                for (let property in errors.data.messages) {
                  if (property === 'email') {
                    this.$validator.errors.add({
                      field: 'email',
                      msg: this.$t('common.error.wrong_email')
                    })
                  }

                  for (let property in errors.data.messages) {
                    if (property === 'services') {
                      this.setError({
                        messageI18Key: 'common.alerts.staff.service_not_found',
                        type: 'failed_creation'
                      })
                      return
                    }
                  }
                }

                this.setError({
                  messageI18Key: 'common.alerts.actions.global_error',
                  type: 'failed_creation'
                })
              })
          }
        } else {
          let error = this.$validator.errors.items[0]
          let message = error.msg

          if (error.rule === 'ext') {
            message = message + this.$t('common.alerts.actions.format_error')

            this.setError({
              type: 'error',
              message: message
            })
          }

          if (error.rule === 'size') {
            this.setError({
              type: 'error',
              message: message
            })
          }
        }
      })
    },
    collectServices() {
      let serviceData = new Set()
      this.serviceCardIsValidStatuses = []

      if (this.$refs['StaffServiceCard']) {
        for (const ref of this.$refs['StaffServiceCard']) {
          ref.$validator.validateAll().then(result => {
            this.serviceCardIsValidStatuses.push(result)
          })

          serviceData.add(ref.formatedData)
        }
      }

      return Array.from(serviceData)
    },
    async validateWorkingHours() {
      return await this.$refs.WorkingHours.$validator.validateAll()
    },
    collectMainPayload() {
      let formData = new FormData()
      let workingHoursPayloadData = this.$refs.WorkingHours.collectPayloadData()
      let selectedServices = this.collectServices()
      let useDynamicPricing = !this.useDynamicPricing ? 0 : 1

      formData.append('salon_id', this.accountInfo.salon_id)
      formData.append('dynamic_pricing', useDynamicPricing)
      formData.append('salon_manager', this.roleField.value)

      for (const key in workingHoursPayloadData.working_hours) {
        let data = workingHoursPayloadData.working_hours[key]
        let idKey = this.staffId !== undefined ? 'id' : 'work_day_id'

        formData.append(`working_hours[${key}][${idKey}]`, data.id)
        formData.append(`working_hours[${key}][start_time]`, data.start_time)
        formData.append(`working_hours[${key}][end_time]`, data.end_time)
        formData.append(`working_hours[${key}][start_lunch_time]`, data.start_lunch_time)
        formData.append(`working_hours[${key}][end_lunch_time]`, data.end_lunch_time)
      }

      for (const key in this.payloadData) {
        if (key === 'password' && this.payloadData[key] === '') {
          continue
        }

        if (key === 'email' && this.payloadData[key] === this.cachedEmail) {
          continue
        }

        formData.append(key, this.payloadData[key])
      }

      if (this.isUpdateProfilePage) {
        formData.append('staff_id', this.staffId)
      }

      if (this.avatar !== null) {
        formData.append('avatar', this.avatar)
      }

      if (!this.useDynamicPricing && this.discount !== 0) {
        formData.append('necessary_discount', this.discount)
      }

      let lastSerivceKey = 0

      this.serviceListForDelete.forEach((id, key) => {
        formData.append(`services[${lastSerivceKey}][service_id]`, id)
        formData.append(`services[${lastSerivceKey}][deleted]`, 1)
        lastSerivceKey++
      })

      let notHasParameters = selectedServices.findIndex(service => service.parameters.length === 0)

      if (notHasParameters >= 0) {
        return false
      }

      selectedServices.forEach((service, key) => {
        formData.append(`services[${lastSerivceKey}][service_id]`, service.id)
        service.parameters.forEach(parameter => {
          formData.append(`services[${lastSerivceKey}][parameter_value_id][]`, parameter)
        })

        lastSerivceKey++
      })

      return formData
    },
    setFormFields(storedProfileInfo) {
      this.useDynamicPricing = !storedProfileInfo.dynamic_pricing ? false : 1

      if (storedProfileInfo.necessary_discount) {
        this.discount = storedProfileInfo.necessary_discount
        this.useDynamicPricing = storedProfileInfo.dynamic_pricing
      }

      for (let [key, value] of Object.entries(storedProfileInfo)) {
        this.formFields.find(field => {
          if (key === 'email') this.cachedEmail = value
          if (field.name === key && value && value !== 'null') field.value = value
        })

        if (key === 'role') {
          if (value === 'Staff') {
            this.roleField.value = 0
          } else {
            this.roleField.value = 1
          }
        }
      }

      this.storeServices = storedProfileInfo.services
      if (storedProfileInfo.workingHours) {
        this.storeWorkDayData = storedProfileInfo.workingHours
      }

      storedProfileInfo.services.forEach(service => {
        this.serviceList.push(service.id)
        this.serviceListSaved.push(service.id)
      })

      this.profilePhotoToDisplay = storedProfileInfo.avatar ? storedProfileInfo.avatar.path : ''
    },
    onImageUpload({ target }) {
      if (!target.files.length) return
      this.$validator.validate('photo').then(result => {
        if (result) {
          const reader = new FileReader()
          reader.onload = e => {
            this.profilePhotoToDisplay = e.target.result
          }

          this.compressed(target.files[0], 5, 330)
            .then(file => {
              this.avatar = file
              reader.readAsDataURL(target.files[0])
            })
            .catch(errors => console.log(errors))
        } else {
          let error = this.$validator.errors.items[0]
          let message = error.msg

          if (error.rule === 'ext') {
            message = message + this.$t('common.alerts.actions.format_error')

            this.setError({
              type: 'error',
              message: message
            })
          }

          if (error.rule === 'size') {
            this.setError({
              type: 'error',
              message: message
            })
          }
        }
      })
    },
    deleteStaff(workerData) {
      this.deleteStaffAction(workerData)
        .then(() => {
          this.setSuccess({
            messageI18Key: 'common.alerts.actions.deleted',
            successCode: 200,
            successExists: true
          })

          this.$router.push('/salon/staff')
        })
        .catch(error => {
          if (error.message === "You can't delete last staff") {
            this.setError(this.deleteLastInfoMessage)
          } else {
            this.setError(this.deleteErrorMessage)
          }
        })
    },
    setSelectedServices(data) {
      this.serviceList = this.serviceList.concat(data)
    },
    removeSelectedServices(id) {
      if (this.serviceListSaved.includes(id) && !this.serviceListForDelete.includes(id)) {
        this.serviceListForDelete.push(id)
      }

      this.storeServices = this.storeServices.filter(service => service.id !== id)
      this.serviceList = this.serviceList.filter(serviceId => serviceId !== id)
    },
    showServiceModal() {
      this.$bvModal.show('serviceModal')
    },
    compressedFile(file) {
      const options = {
        maxSizeMB: 5,
        maxWidthOrHeight: 330,
        useWebWorker: true
      }

      return imageCompression(file, options)
        .then(compressedFile => {
          this.avatar = compressedFile
        })
        .catch(error => {
          console.log(error.message)
        })
    }
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #ff6b09;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
