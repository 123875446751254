<template>
  <div class="salon-flow col-12 pb-2-5">
    <form>
      <div class="mx-n2-5">
        <div class="form-group px-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent">
                <font-awesome-icon :icon="['far', 'search']" class="fa-w-16 text-primary"></font-awesome-icon>
              </span>
            </div>
            <input
              v-model="searchWord"
              type="text"
              name="search-word"
              class="form-control w-100 px-1-5 border-left-0"
            />
          </div>
        </div>
      </div>
      <div class="service-container mx-n2-5 mb-4">
        <div
          v-for="service in serviceList"
          :key="`service-${service.id}`"
          class="checkbox-container px-4"
          :class="{ selected: selectedToUpdate.includes(service.id) }"
        >
          <div class="checkbox centered-y">
            <label class="mb-0 cursor-pointer py-2">
              <input
                v-model="selectedToUpdate"
                :value="service.id"
                :name="`service-checkbox-${service.id}`"
                type="checkbox"
                class="activateWorkingHours"
              />
              <i class="input-helper"></i>
              <div class="text-black h6 mb-0 font-weight-normal">
                {{ service.title }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row px-2">
        <div class="col">
          <b-button variant="checkbox" size="lg" class="btn-block px-1" @click="cancel()">
            <span>Cancel</span>
          </b-button>
        </div>
        <div class="col">
          <b-button variant="primary" size="lg" class="btn-block px-1" @click="setSelectedServicesEmit">
            <span>{{ $t('common.add_service') }}</span>
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StaffServiceForm',
  props: {
    services: {
      type: Array,
      default: () => []
    },
    selectedServices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchWord: '',
      selectedToUpdate: [],
      serviceToSelect: []
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    }),
    serviceList() {
      return this.serviceToSelect.filter(service => service.title.toLowerCase().includes(this.searchWord.toLowerCase()))
    }
  },
  mounted() {
    this.serviceToSelect = this.services.filter(service => !this.selectedServices.includes(service.id))
  },
  methods: {
    cancel() {
      this.$bvModal.hide('serviceModal')
    },
    setSelectedServicesEmit() {
      this.$emit('set-selected-services', this.selectedToUpdate)
      this.cancel()
    }
  }
}
</script>

<style lang="scss" scoped></style>
